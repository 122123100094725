<template>
  <div class="bodywhite">
    <div class="header-light">
      <div class="container">
        <div class="row">
          <div class="col-2">
          </div>
          <div class="col-8 col-center">
            <p class="pbolduppercase fontcolor-mainblue">Werte Erfassung</p>
          </div>
          <button class="col-2 col-right" @click="schliessenzuhome()">
              <img src="../assets/icons/close.svg">
          </button>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="paddingtop155">
        <p class="psemibold fontcolor-mainblue">Hast du eines oder mehrere der genannten Symptome?</p>

        <div class="margintop50">
            <input type="checkbox" id="symptommüdigkeit" v-model="symptommuedigkeit" name="symptommüdigkeit"><label for="symptommüdigkeit">Müdigkeit</label>
            <input type="checkbox" id="symptomschwaeche" v-model="symptomschwaeche" name="symptomschwaeche"><label for="symptomschwaeche">Schwäche</label>
            <input type="checkbox" id="symptomverringerteurinausscheidung" v-model="symptomverringerteurinausscheidung" name="symptomverringerteurinausscheidung"><label for="symptomverringerteurinausscheidung">Verringerte Urinausscheidung</label>
            <input type="checkbox" id="symptomschwellungderbeine" v-model="symptomschwellungderbeine" name="symptomschwellungderbeine"><label for="symptomschwellungderbeine">Schwellung der Beine (Ödeme)</label>
            <input type="checkbox" id="symptomschmerzenimtransplantatbereich" v-model="symptomschmerzenimtransplantatbereich" name="symptomschmerzenimtransplantatbereich"><label for="symptomschmerzenimtransplantatbereich">Schmerzen im Transplantatbereich</label>
            <input type="checkbox" id="symptomsonstiges" v-model="symptomsonstiges" name="symptomsonstiges"><label for="symptomsonstiges">Sonstiges</label>
        </div>
        <!--<ul class="margintop50 liste" > https://codepen.io/quinlo/pen/ReMRXz 
            <li>
              <label for="symptom_müdigkeit">
                <input type="checkbox" name="mode" id="symptom-müdigkeit"/>
                <span>Müdigkeit</span>
              </label>
            </li>
            <li>
              <label for="symptom_schwäche">
                <input type="checkbox" name="mode" id="symptom-schwaeche"/>
                <span>Schwäche</span>
              </label>
            </li>
            <li>
              <label for="symptom_verringerteurinausscheidung">
                <input type="checkbox" name="mode" id="symptom-verringerteurinausscheidung"/>
                <span>Verringerte Urinausscheidung</span>
              </label>
            </li>
            <li>
              <label for="symptom_schwellungderbeine">
                <input type="checkbox" name="mode" id="symptom-schwellungderbeine"/>
                <span>Schwellung der Beine (Ödeme)</span>
              </label>
            </li>
            <li>
              <label for="symptom_schmerzenimtransplantatbereich">
                <input type="checkbox" name="mode" id="symptom-schmerzenimtransplantatbereich"/>
                <span>Schmerzen im Transplantatbereich</span>
              </label>
            </li>
            <li>
              <label for="symptom_sonstiges">
                <input type="checkbox" name="mode" id="symptom-sonstiges"/>
                <span>Sonstiges</span>
              </label>
            </li>
          </ul>-->
      </div>
    </div>


    <div class="stepnavigation backgroundcolor-beige">
      <div class="container">
        <button type="button" value="Submit" class="buttonXS backgroundcolor-white col-2 col-center center" @click="werteabfragegewichtzuwerteabfrageallgemeinzustand()">
          <img class="navicon:active" src="../assets/icons/arrow.svg">
        </button>
        <button type="button" value="Submit" class="buttonS fontcolor-white backgroundcolor-mainblue" @click="werteabfragegewichtzuwerteabfragebericht()">
          <p class="pbold display-inline fontcolor-white">Weiter</p>
          <img class="display-inline" src="../assets/icons/arrow_white.svg" alt="">
        </button>
      </div>
    </div>

  </div>
</template>

<script>
import { mapActions } from 'vuex';
import router from '../router';

export default {
  data() {
      return {
        symptomsonstiges: false,
        symptomschmerzenimtransplantatbereich: false,
        symptomschwellungderbeine: false,
        symptomverringerteurinausscheidung: false,
        symptomschwaeche: false,
        symptommuedigkeit: false,
      };
  },
  components: {
  },
  mounted() {
    //this.focusInput();
  },
  methods: {
    ...mapActions(['setWert']),
    /*focusInput() {
      this.$refs.input.$el.focus();
    },*/
    schliessenzuhome() {
      router.push({path: '/home'})
    },
    werteabfragegewichtzuwerteabfrageallgemeinzustand() {
      router.push({path: '/werteabfrageallgemeinzustand'})
    },
    werteabfragegewichtzuwerteabfragebericht() {


      if(this.symptommüdigkeit){
        this.setWert({
            name: "symptommuedigkeit", 
            wert: this.symptommuedigkeit,
        });
      }
      if(this.symptomschwaeche){
        this.setWert({
            name: "symptomschwaeche", 
            wert: this.symptomschwaeche,
        });
      }
      if(this.symptomverringerteurinausscheidung){
        this.setWert({
            name: "symptomverringerteurinausscheidung", 
            wert: this.symptomverringerteurinausscheidung,
        });
      }
      if(this.symptomschwellungderbeine){
        this.setWert({
            name: "symptomschwellungderbeine", 
            wert: this.symptomschwellungderbeine,
        });
      }
      if(this.symptomschmerzenimtransplantatbereich){
        this.setWert({
            name: "symptomschmerzenimtransplantatbereich", 
            wert: this.symptomschmerzenimtransplantatbereich,
        });
      }
      if(this.symptomsonstiges){
        this.setWert({
            name: "symptomsonstiges", 
            wert: this.symptomsonstiges,
        });
      }

      router.push({path: '/werteabfragewertebericht'})
    },
  }
}
</script>


<style scoped>

  .stepnavigation {
    background-color: #F6F6F6;
    overflow: hidden;
    position: fixed;
    width: 100%;
    height: 95px;
    bottom: 0;
    padding-top: 15px;
    z-index: 2;
  }

  label {
    display: inline-block;
    padding: 10px 15px;
    border: #0B1010 1px solid;
    border-radius: 40px; 
    color: #0B1010;
    display: flex;
    width: fit-content;
    text-align: right;
    margin-bottom: 10px;
  }
  input[type="checkbox"] {
      display:none;

  }
  input[type="checkbox"]:checked + label {
      color: #FFFFFF;
      fill: #ffffff;
      background-color: #0B1010;

  }
/*
  .input {
      color: #0B1010;
      text-align: right;
      font-size: 65px;
      font-weight: bold;
      width: 85%;
      border: none;
      height: 320px;
  }

  .input:focus {
  outline: none;
  }

  label {
      display: inline-block;
      margin-bottom: 0.5rem;
  }

  .liste li {
      list-style-type: none;
      text-align: right;
      padding: 12px 0px;
  }

  input[type="checkbox"] {
    opacity: 0%;
    position: absolute;
  }

  input[type="checkbox"] + span {
    color: #0B1010;
    border-radius: 40px;
    padding: 12px;
    transition: all 0.4s;
    -webkit-transition: all 0.4s;
    border: #0B1010 1px solid;
  }


  input[type="checkbox"]:checked + span {
    color: #FFFFFF;
    fill: #ffffff;
    background-color: #0B1010;
  }
  */
</style>